import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Select,
  Form,
  Slider,
  DatePicker,
  DatePickerProps,
  Divider,
  Space,
  Input,
  Button,
  Modal,
  Tag,
} from "antd";
import moment from "moment";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import Swal from "sweetalert2";
import { InputField } from "../../../../components/inputFields/InputField";
import QuillEditor from "react-quill";
import { Country, State, City } from "country-state-city";
import { createJob } from "../../../../serviceApi/adminApi/JobApi";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import { getDomainNamebyuser } from "../../../../serviceApi/adminApi/DomainApi";
import { getCategories } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { getSkills } from "../../../../serviceApi/adminApi/SkillApi";
import {
  jobRoleData,
  perksBenefits,
  qualification,
  modules,
  formats,
} from "../../../../constant/job-role";

interface Domain {
  uuid: string;
  domainId: string;
  name: string;
}
export const CreateJob = () => {
  const { user } = useAuth0();
  const userId: any = user?.email;
  const navigate = useNavigate();
  const [jobNameErr, setJobNameErr] = useState("");
  const [organisationErr, setOrganisationErr] = useState("");

  const handleJob = async () => {
    const response = await createJob({
      title,
      status,
      description,
      experience: `${experience[0]} - ${experience[1]}`,
      domain,
      skills,
      job_level: jobLevel,
      job_role: jobRole,
      job_type: jobType,
      job_workingType: jobWorkingType,
      organisation,
      employerId: userId,
      country: countriess,
      region: statedata,
      city: citydata,
      qualifications,
      benefits: selectedBenfits,
      job_start_date: startDate,
      job_end_date: endDate,
      salary: `${currency} ${salary[0] * 1000}- ${currency} ${
        salary[1] * 1000
      }/Month`,
    });
    if (response?.data?.createJob?.uuid) {
      Swal.fire({
        icon: "success",
        title: "Job Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/subadmin/admin/job/list");
      });
    }
  };

  const [title, setTitle] = useState<string>("");
  const [jobRole, setJobRole] = useState<string>("");
  const [organisation, setOrganisation] = useState<string>("");
  const [salary, setSalary] = useState<any>([1, 50]);
  const [experience, setExperience] = useState<any>([0, 3]);
  const [skills, setSkills] = useState<any>([]);
  const [domain, setDomain] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [jobType, setJobtype] = useState<string>("");
  const [jobWorkingType, setJobWorkingType] = useState<string>("");
  const [jobLevel, setJobLevel] = useState<string>("");
  const [countriess, setCountry] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [citydata, setCitydata] = useState<any>([]);
  const [statedata, setStateData] = useState<any>([]);
  const [description, setDesciption] = useState<string>("");
  const [errorstatus, setErrorstatus] = useState<boolean>(true);
  const [descriptionerror, setDesciptionerror] = useState<string>("");
  const [salaryerror, setSalaryerror] = useState<string>("");
  const [qualifications, setQualification] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [items, setItems] = useState<any>(qualification);
  const [benefits, setBenefits] = useState<any>(perksBenefits);
  const [name, setName] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [Benefitname, setBenefitName] = useState<any>("");
  const [selectedBenfits, setSelectedBenefits] = useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [domains, setDomains] = React.useState([]);
  const [skillList, setSkillList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [catValue, setCatValue] = useState<string>("");
  const [open, setOpen] = useState(false);

  const [index, setIndex] = useState(6);
  const inputRef = useRef<any>(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onBenfitsChanges = (event) => {
    setBenefitName(event.target.value);
  };
  const addItem = (e: any, id: any) => {
    e.preventDefault();
    const list = [...items];
    if (e.target.value.trim().length > 0) {
      list[id] = { value: e.target.value, label: e.target.value };
      setItems(list);
      setIndex(id + 1);
    }
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleQualification = (event) => {
    setQualification(event);
  };

  const handleBenefits = (event) => {
    setSelectedBenefits(event);
  };

  const addBenefits = (e: any, id: any) => {
    e.preventDefault();
    const list = [...benefits];
    if (e.target.value.trim().length > 0) {
      list[id] = { value: e.target.value, label: e.target.value };
      setBenefits(list);
      setIndex(id + 1);
    }
    setBenefitName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const countries: any = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
    currency: country.currency,
  }));

  const selectcountry = (countryCode, option) => {
    const { currency } = option;
    setCurrency(currency);
    setCountry(countryCode);
    const stateData = State.getStatesOfCountry(countryCode);
    const statess = stateData.map((state) => ({
      value: state.isoCode,
      label: state.name,
      country: state.countryCode,
    }));
    setStates(statess);
  };

  const selectstate = (val) => {
    setStateData(val);
    const cityData = City.getCitiesOfState(countriess, val);
    const cities = cityData.map((city) => ({
      label: city.name,
      value: city.name,
    }));
    setCity(cities);
  };

  const selectcity = (val) => {
    setCitydata(val);
  };

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },

    { text: "Create Jobs" },
  ];

  const getDomainsListbyUser = async () => {
    const domains = await getDomainNamebyuser(user?.email);
    if (domains?.data?.domainNameByuser?.length <= 0) {
      setShowModal(true);
    } else {
      setDomains(domains?.data?.domainNameByuser);
      setShowModal(false);
    }
  };

  const handleDomain = async (domain) => {
    if (categoryList.length > 0) {
      setCatValue("");
    }
    setDomain(domain);
    const categories = await getCategories(domain);
    if (categories?.data?.category) {
      setCategoryList(categories?.data?.category);
    }
  };

  const selectCategory = async (newValue: string) => {
    if (categoryList.length > 0) {
      setSkills("");
    }
    setCatValue(newValue);
    const res = await getSkills(newValue);
    if (res?.data?.skill) {
      setSkillList(res?.data?.skill);
    }
  };

  const domainOptionsData = domains?.map((domain: Domain) => {
    return {
      value: domain?.domainId,
      label: domain?.name,
    };
  });

  const categoryOptionsData = categoryList?.map((category: Domain) => {
    return {
      value: category?.uuid,
      label: category?.name,
    };
  });

  const skillOptionsData = skillList?.map((skill: Domain) => {
    return {
      value: skill?.name,
      label: skill?.name,
    };
  });

  const onChange: DatePickerProps["onChange"] = (_, dateStr) => {
    setStartDate(dateStr);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (_, dateStr) => {
    setEndDate(dateStr);
  };
  const handleSalary = (e) => {
    setSalary(e);
  };

  const navigatePageToDomain = () => {
    navigate("/subadmin/AllDomains/list");
  };
  useEffect(() => {
    if (salary[0] < 1) {
      setSalaryerror("Salary must be greater than 0");
    } else {
      setSalaryerror("");
    }

    if (description?.trim()?.length < 250) {
      setDesciptionerror("Description must be 250 character");
    } else {
      setDesciptionerror("");
    }
    if (
      title?.trim()?.length > 0 &&
      description?.trim()?.length >= 250 &&
      experience &&
      domain?.trim()?.length > 0 &&
      catValue?.trim()?.length > 0 &&
      skills?.length > 0 &&
      jobLevel?.trim()?.length > 0 &&
      jobRole?.trim()?.length > 0 &&
      jobWorkingType?.trim()?.length > 0 &&
      countriess?.length > 0 &&
      citydata.length > 0 &&
      statedata.length > 0 &&
      organisation?.trim()?.length > 0 &&
      qualification.length > 0 &&
      status?.trim()?.length > 0 &&
      startDate &&
      endDate
    ) {
      setErrorstatus(false);
    } else {
      setErrorstatus(true);
    }
  }, [
    title,
    status,
    description,
    experience,
    domain,
    skills,
    jobLevel,
    jobRole,
    jobWorkingType,
    organisation,
    countries,
    statedata,
    salary,
    citydata,
    catValue,
    startDate,
    endDate,
  ]);

  const handleRole = (role) => {
    setJobRole(role);
  };

  useEffect(() => {
    getDomainsListbyUser();
  }, []);

  return (
    <>
      <section
        className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5"
        onScroll={() => setOpen(false)}
      >
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Create Jobs
        </p>
        <div className="lg:mb-4 lg:mt-0 my-3">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="bg-white rounded-2xl mt-10 p-5 shadow-lg border">
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-x-20">
            <div className="mb-2">
              <InputField
                id="Job Title"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md lg:w-[800px] p-4"
                type="text"
                placeholder="Enter Job title"
                value={title}
                onChange={setTitle}
                containsSpecialCharsMsg={setJobNameErr}
              />
              <span className="text-red-500">{jobNameErr}</span>
            </div>

            <div className="flex flex-col relative">
              <Form.Item
                label={
                  <span>
                    Select Job Role
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please select job role",
                  },
                ]}
              >
                <Select
                  onChange={(role) => handleRole(role)}
                  showSearch
                  placeholder="Search / Select Job Role"
                  // open={open}
                  // onMouseDown={()=>setOpen(true)}
                  // onBlur={()=>setOpen(false)}
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={jobRoleData}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Select Job Domain
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Domain",
                  },
                ]}
              >
                <Select
                  onChange={(domain) => handleDomain(domain)}
                  placeholder="Search / Select Domain"
                  optionFilterProp="children"
                  showSearch
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={domainOptionsData}
                />
              </Form.Item>
            </div>

            <div className="flex flex-row gap gap-8 ml-2 ">
              <div className="w-1/2 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Category
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Category",
                    },
                  ]}
                >
                  <Select
                    onChange={(category) => selectCategory(category)}
                    placeholder="Search / Select Job Category"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={categoryOptionsData}
                  />
                </Form.Item>
              </div>
              <div className="w-1/2 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job Skills
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Skills",
                    },
                  ]}
                >
                  <Select
                    onChange={(skill) => setSkills(skill)}
                    mode="multiple"
                    placeholder="Search / Select Job Skills"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={skillOptionsData}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="w-1/3 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job Country
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Country.",
                    },
                  ]}
                >
                  <Select
                    onChange={(val, option) => selectcountry(val, option)}
                    showSearch
                    placeholder="Select Country"
                    optionFilterProp="children"
                    style={{
                      marginLeft: "2px",
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    options={countries}
                  />
                </Form.Item>
              </div>
              <div className="w-1/3 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job State
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Skills.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onChange={(val) => selectstate(val)}
                    placeholder="Select state"
                    optionFilterProp="children"
                    style={{}}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={states}
                  />
                </Form.Item>
              </div>
              <div className="w-1/3 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job City
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Skills.",
                    },
                  ]}
                >
                  <Select
                    onChange={(val) => selectcity(val)}
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    style={{}}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={city}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="job-salary"
                className="font-primary capitalize text-sm lg:text-base"
              >
                Expected Experience in year
                <span className="text-red-600 text-sm"> *</span>
              </label>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={[1, 2]}
                max={30}
                onChange={(experience) => setExperience(experience)}
              />
              <span>
                Expected Experience is {experience[0]} - {experience[1]}
              </span>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Type
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Job Type.",
                  },
                ]}
              >
                <Select
                  onChange={(type) => setJobtype(type)}
                  placeholder="Search / Select Job Type"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Contract", label: "Contract" },
                    { value: "Full-Time", label: "Full-Time" },
                    { value: "Part-Time", label: "Part-Time" },
                    { value: "Internship", label: "Internship" },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="job-salary"
                className="font-primary capitalize text-sm lg:text-base"
              >
                Expected Salary in {currency} thousands / month
                <span className="text-red-600 text-sm"> *</span>
              </label>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={[1, 50]}
                onChange={(e) => handleSalary(e)}
              />
              <span>
                Selected Salary is {currency} {salary[0] * 1000} - {currency}{" "}
                {salary[1] * 1000} /Month{" "}
              </span>
              {salaryerror && (
                <p className="text-red-600 text-sm">{salaryerror}</p>
              )}
            </div>

            <div className="flex flex-col">
              <InputField
                id="Organization"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md lg:w-[710px] relative"
                type="text"
                placeholder="Organisation Name"
                value={organisation}
                onChange={setOrganisation}
                containsSpecialCharsMsg={setOrganisationErr}
              />
              <span className="text-red-500 mt-[10px]">{organisationErr}</span>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Working Type
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please Select Working type",
                  },
                ]}
              >
                <Select
                  onChange={(workingtype) => setJobWorkingType(workingtype)}
                  placeholder="Search / Select Job Working Type"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  showSearch
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Remote", label: "Remote" },
                    { value: "Hybrid", label: "Hybrid" },
                    { value: "On-Site", label: "On-Site" },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Level
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Job Level",
                  },
                ]}
              >
                <Select
                  onChange={(level) => setJobLevel(level)}
                  placeholder="Search / Select Job Level"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")?.includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Entry Level", label: "Entry Level" },
                    { value: "Junior Level", label: "Junior Level" },
                    { value: "Senior Level", label: "Senior Level" },
                    { value: "Expert", label: "Expert" },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Status
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Job Status.",
                  },
                ]}
              >
                <Select
                  onChange={(status) => setStatus(status)}
                  placeholder="Search / Select Job Status"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")?.includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Benefits
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Perks and Benefits.",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "99%",
                  }}
                  placeholder="Perks and Benefits"
                  mode="multiple"
                  allowClear
                  onChange={(event) => handleBenefits(event)}
                  value={selectedBenfits}
                  maxTagCount="responsive"
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  // open={true}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={Benefitname}
                          onChange={onBenfitsChanges}
                          onBlur={(e) => {
                            addBenefits(e, index);
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                      </Space>
                    </>
                  )}
                  options={benefits.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                  }))}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Education Qualification
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please select  Education Qualification .",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "99%",
                  }}
                  onChange={(event) => handleQualification(event)}
                  placeholder="Qualifications"
                  mode="multiple"
                  allowClear
                  maxTagCount="responsive"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                          onBlur={(e) => addItem(e, index)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                      </Space>
                    </>
                  )}
                  options={items.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                  }))}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Start Date
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Event Start Date",
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: "99%",
                  }}
                  onChange={onChange}
                  disabledDate={(current) => {
                    return moment().subtract(1, "days") >= current;
                  }}
                  className="eventDatepicker lg:p-3.5 p-3.5 text-sm lg:mt-2 border border-gray-300 cursor-text focus:outline-none rounded-lg"
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job End Date
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please Select Perks and Benefits.",
                  },
                ]}
              >
                <DatePicker
                  style={{
                    width: "99%",
                  }}
                  onChange={onChangeEndDate}
                  disabledDate={(current) => {
                    return moment().subtract(1, "days") >= current;
                  }}
                  className="eventDatepicker lg:p-3.5 p-3.5 text-sm lg:mt-2 border border-gray-300 cursor-text focus:outline-none  rounded-lg"
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="job-description"
              className="font-primary capitalize text-sm lg:text-base"
            >
              Job Description
              <span className="text-red-600 text-sm"> *</span>
            </label>

            <QuillEditor
              theme="snow"
              formats={formats}
              modules={modules}
              style={{ width: "100%" }}
              value={description}
              onChange={(e) => setDesciption(e)}
            />
            {descriptionerror && (
              <p className="text-red-600 text-sm">{descriptionerror}</p>
            )}
          </div>

          <div className="mt-4">
            <button
              className={`${
                errorstatus
                  ? "border rounded-full text-sm lg:text-base focus:outline-none font-primary text-white py-2 px-5 lg:px-14 bg-gray-300 cursor-not-allowed border-gray-300"
                  : "border rounded-full text-sm lg:text-base focus:outline-none font-primary text-white py-2 px-5 lg:px-14 bg-gray-800  border-gray-300"
              }`}
              onClick={() => handleJob()}
              disabled={errorstatus ? true : false}
            >
              Create
            </button>
          </div>
        </div>
        <Modal
          centered
          open={showModal}
          onOk={() => navigatePageToDomain()}
          onCancel={() => navigatePageToDomain()}
        >
          <NavLink to={`/subadmin/AllDomains/list`}>
            <h1>
              Please take approval for at least one Domain before creating Job
            </h1>
            <button className="bg-brand-500 font-primary text-white px-5 py-1 rounded-lg capitalize">
              Take Domain Approval
            </button>
          </NavLink>
        </Modal>
      </section>
    </>
  );
};
