import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  deleteQuestion,
  getCv,
  getUsers,
  updateUser,
} from "../../serviceApi/api";
import { Tabs, Breadcrumb, DatePicker, Progress } from "antd";
import { useNavigate } from "react-router-dom";
import type { DatePickerProps } from "antd";

import {
  InfoCircleOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import type { TabsProps } from "antd";
import { InputField } from "../../components/inputFields/InputField";
import moment from "moment";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import Swal from "sweetalert2";
import { getCookie } from "../../utils";
import { nameInputValid } from "../../utils/validation";
import ImageField from "../../components/imageField/ImageField";
import "react-phone-number-input/style.css";
import { loginContext } from "../../components/context/LoginContext";
import Logo from "../../assets/leftnavicons/Logo.svg";
import ApiAccessToken from "./Admin/apitoken/ApiAccessToken";

export const Profile = () => {
  const { user } = useAuth0();
  const email: any = user?.email;
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<any>({});
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState<any>("");
  const [address, setAddress] = useState("");
  const [formStatus, setFormStatus] = useState(true);
  const [Errfirstname, setErrfirstname] = useState("");
  const [Errlastname, setErrLastname] = useState("");
  const [ErrMobile, setErrMobile] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [value, setValue] = useState<any>();
  const [userUuid, setUserUuid] = useState();
  const { firstName, setFirstName, setUserProfile }: any =
    useContext(loginContext);
  const [coverImagePreview, setCoverImagePreview] = useState<any>();
  const [progress, setProgress] = useState(0);
  const [userCv, setUserCv] = useState("");

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDob(dateString);
  };
  const getCvDetails = async (id) => {
    const res = await getCv(id);
    if (res?.data?.getCv?.length > 0) {
      setUserCv(res?.data?.getCv[0].cv);
    }
  };
  const getUserDetails = async () => {
    if (email != "") {
      const students = await getUsers(email);
      if (students?.data?.user) {
        setUserDetails(students?.data?.user[0]);
        setFirstname(students?.data?.user[0]?.firstName);
        setLastname(students?.data?.user[0]?.lastName);
        setAddress(students?.data?.user[0]?.Address);
        setMobile(students?.data?.user[0]?.Contact_number);
        setDob(students?.data?.user[0]?.dob);
        setGender(students?.data?.user[0]?.gender);
        setValue(students?.data?.user[0]?.Contact_number);
        setUserUuid(students?.data?.user[0]?.uuid);
        setUserProfile(students?.data.user[0]?.image);
        getCvDetails(students?.data?.user[0]?.uuid);
      }
    }
  };
  const handleSubmit = async () => {
    const userData = {
      gender,
      Address: address,
      firstName: firstname,
      lastName: lastname,
      dob,
      userId: email,
      Contact_number: mobile,
      code: value,
    };
    const Users = await updateUser(userData);
    if (Users?.data?.updateUser?.message == "data updated successfully") {
      setFirstName(firstname);
      Swal.fire({
        icon: "success",
        title: "User Details updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        if (userDetails?.role == "SuperAdmin") {
          navigate("/home/dashboard");
        } else if (userDetails?.role == "Admin") {
          navigate("/subadmin/profile");
        } else {
          navigate("/student/profile");
        }
      }, 1800);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data please remove symbols.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const uploadCV = async (event: React.ChangeEvent<HTMLInputElement> | any) => {
    event.preventDefault();
    const selectedFiles = event.target.files[0];

    if (
      selectedFiles?.type == "application/pdf" ||
      selectedFiles?.type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      if (userCv) {
        const formData = new FormData();
        formData.append("image", userCv);
        formData.append("uuid", userDetails.uuid);
        formData.append("type", "cv");
        const { data }: any = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
            onUploadProgress: (progressEvent: any) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          }
        );
      }

      const formData = new FormData();
      formData.append("image", selectedFiles);
      formData.append("uuid", userDetails.uuid);
      formData.append("type", "cv");
      try {
        axios
          .post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
              onUploadProgress: (progressEvent: any) => {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
              },
            }
          )
          .then((res) => {
            if (res?.data?.status === true) {
              setCoverImagePreview(true);
              Swal.fire({
                icon: "success",
                title: "File successfully uploaded ",
                showConfirmButton: false,
                timer: 3000,
              });
              getCvDetails(userDetails?.uuid);
            } else if (res?.data?.status === false) {
              Swal.fire({
                icon: "warning",
                title: "something went wrong.",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (error: any) {
        throw new Error(error);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid cv type",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const userProfileupload = async (event: any) => {
    event.preventDefault();
    const files = event.target.files[0];

    if (
      files?.type == "image/png" ||
      files?.type == "image/jpeg" ||
      files?.type == "image/gif"
    ) {
      if (files.size >= 2097152) {
        Swal.fire({
          icon: "warning",
          title: "Image size should be less than 2MB.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        const formData = new FormData();
        formData.append("image", files);
        formData.append("uuid", userDetails.uuid);
        formData.append("type", "profile");
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );

          if (data.message == "image uploaded Successfully") {
            await getUserDetails();
          }
        } catch (error: any) {
          throw new Error(error);
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid image type",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleInputs = (e: any) => {
    if (e.target.name == "mobile_number") {
      if (e.target.value.length > 10 || e.target.value.length < 10) {
        setErrMobile("Mobile Must be 10 charactors");
        setMobile("");
      } else {
        setErrMobile("");

        setMobile(e.target.value);
      }
    } else if (nameInputValid(e.target.value)) {
      if (e.target.name == "first_name") {
        setFirstname(e.target.value);
        setErrfirstname("");
      }

      if (e.target.name == "last_name") {
        setLastname(e.target.value);
        setErrLastname("");
      }

      if (e.target.name == "gender") {
        setGender(e.target.value);
      }
    } else {
      if (e.target.name == "first_name") {
        setErrfirstname("First name should be character only");
        setFirstname(e.target.value);
      }

      if (e.target.name == "last_name") {
        setErrLastname("Last name should be character only");
        setLastname(e.target.value);
      }

      if (e.target.name == "gender") {
        setGender("");
      }
    }
  };

  const deleteProfileImage = async (e: any, userUuid: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", userDetails?.image);
    formData.append("uuid", userUuid);
    formData.append("type", "profile");
    const { data }: any = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
      formData,
      {
        headers: {
          enctype: "multipart/form-data",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    if (data?.status == true) {
      await deleteQuestion(userUuid, "user");
      getUserDetails();
    }
  };

  const deleteCoverImage = async (e: any, userUuid: any) => {
    setCoverImagePreview(URL.revokeObjectURL(coverImagePreview));
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", userDetails?.cv);
    formData.append("uuid", userUuid);
    formData.append("type", "cv");
    const { data }: any = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
      formData,
      {
        headers: {
          enctype: "multipart/form-data",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    if (data?.status == true) {
      await deleteQuestion(userUuid, "user");
      getUserDetails();
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [email]);

  const time: any = new Date();
  const mondDiff = time - new Date(dob)?.getTime();
  const age_dt = new Date(mondDiff);
  const year = age_dt.getUTCFullYear();
  const age = Math.abs(year - 1970);

  useEffect(() => {
    if (
      firstname?.trim()?.length > 0 &&
      nameInputValid(firstname) &&
      nameInputValid(lastname) &&
      lastname?.trim()?.length > 0 &&
      dob &&
      new Date(dob) < new Date() &&
      gender &&
      address?.trim()?.length > 0 &&
      age > 5
    ) {
      if (value && isValidPhoneNumber(value.toString())) {
        setFormStatus(false);
        setErrMobile("");
      } else {
        setFormStatus(true);
        setErrMobile("Please Enter valid number ");
      }
    } else {
      setFormStatus(true);
      if (value?.length > 0 && isValidPhoneNumber(value.toString())) {
        setErrMobile("");
      } else {
        setFormStatus(true);
        setErrMobile("Please Enter valid number ");
      }
    }
  }, [firstname, lastname, gender, address, mobile, value, dob, age]);

  const accountEditText = (
    <div className="flex group hover:text-blue-400 items-center flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5  mr-1 group focus:text-blue-400"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
        />
      </svg>
      Account
    </div>
  );

  const basicInfotab = (
    <div className="flex group hover:text-blue-400 items-center flex-row">
      <span className="w-5 h-5 -mt-1  mr-1 group focus:text-blue-400">
        <InfoCircleOutlined />
      </span>
      Basic Info
    </div>
  );

  const privacyTab = (
    <div className="flex group hover:text-blue-400 items-center flex-row">
      <span className="w-6 h-6  group focus:text-blue-400">
        <SafetyCertificateOutlined />
      </span>
      Privacy
    </div>
  );

  const basicInfo = (
    <div className=" max-w-8xl mb-10 flex flex-col lg:flex-col space-x-5 h-full mx-auto bg-white shadow-lg p-3 lg:p-3 rounded-xl">
      <div className="lg:w-full p-1 lg:p-5">
        <h3 className=" text-lg font-primary">Basic info</h3>
        <div className="space-y-3 pt-5">
          <div className="grid grid-cols-2 w-full border-b pb-3">
            <div className="font-primary text-sm lg:text-base">Name</div>
            <div className="text-sm capitalize md:text-base lg:text-base font-primary  text-black text-left ">
              {userDetails?.firstName?.slice(0, 15)}{" "}
              {userDetails?.lastName?.slice(0, 5)}
            </div>
          </div>
          <div className="grid grid-cols-2 w-full border-b pb-3">
            <div className="font-primary text-sm lg:text-base text-[#1C1D1F]">
              Gender
            </div>
            <div className="text-sm capitalize md:text-base lg:text-base font-primary  text-[#1C1D1F]   text-left ">
              {userDetails?.gender ? userDetails?.gender : "-"}
            </div>
          </div>
          <div className="grid grid-cols-2 w-full border-b pb-3">
            <div className="font-primary text-sm lg:text-base text-[#1C1D1F]">
              Email
            </div>
            <div
              className="text-sm md:text-base lg:text-base font-primary  text-[#1C1D1F]   text-left truncated"
              title={userDetails?.userId}
            >
              {userDetails?.userId}
            </div>
          </div>
          <div className="grid grid-cols-2 w-full border-b pb-3">
            <div className="font-primary text-sm lg:text-base text-[#1C1D1F]">
              Birthday
            </div>
            <div className="text-sm md:text-base lg:text-base font-primary  text-[#1C1D1F]   text-left ">
              {userDetails?.dob ? userDetails?.dob : "YYYY/MM/DD"}
            </div>
          </div>
          <div className="grid grid-cols-2 w-full border-b pb-3">
            <div className="font-primary text-sm lg:text-base text-[#1C1D1F]">
              Mobile Number
            </div>
            <div className="text-sm md:text-base lg:text-base font-primary  text-[#1C1D1F]   text-left ">
              {userDetails?.Contact_number
                ? userDetails?.Contact_number
                : "XXXXXXXXXX"}
            </div>
          </div>
          <div className="grid grid-cols-2 w-full border-b pb-3">
            <div className="font-primary text-sm lg:text-base text-[#1C1D1F]">
              Address
            </div>
            <div className="text-sm md:text-base lg:text-base font-primary  text-[#1C1D1F]   text-left ">
              {userDetails?.Address ? userDetails?.Address?.slice(0, 80) : "-"}
              {userDetails?.Address?.length > 81 ? "..." : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const Account = (
    <div className="mb-10  flex flex-col lg:flex-col lg:space-x-5 h-full mx-auto bg-white">
      {/* basic info */}
      <h3 className=" text-lg font-primary ml-5">Account Information</h3>
      <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-10 lg:gap-y-8 gap-y-2">
        <div>
          <label
            htmlFor="first_name"
            className="font-primary capitalize text-sm lg:text-base"
          >
            First name
            <span className="text-red-600 text-lg ml-1">*</span>
          </label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={firstname}
            onChange={(e) => handleInputs(e)}
            autoComplete="off"
            className="border border-gray-300 focus:outline-none  text-sm rounded-lg lg:mt-2 block w-full lg:p-3.5 p-3.5"
            placeholder="First name"
            required
          />
          <span className="text-red-600 mt-[6px] absolute">
            {Errfirstname && Errfirstname ? Errfirstname : ""}
          </span>
        </div>
        <div>
          <label
            htmlFor="first_name"
            className="font-primary capitalize text-sm lg:text-base"
          >
            Last name
            <span className="text-red-600 text-lg ml-1">*</span>
          </label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={lastname}
            onChange={(e) => handleInputs(e)}
            autoComplete="off"
            className="border border-gray-300 focus:outline-none  text-sm rounded-lg lg:mt-2 block w-full lg:p-3.5 p-3.5"
            placeholder="Last name"
            required
          />
          <span className="text-red-600 mt-[6px] absolute">
            {Errlastname && Errlastname ? Errlastname : ""}
          </span>
        </div>
        <div>
          <label
            htmlFor="last_name"
            className="font-primary text-sm lg:text-base"
          >
            D.O.B (minimum age should be greater than 5 years)
            <span className="text-red-600 text-lg ml-1">*</span>
          </label>
          <DatePicker
            className="userProfileDatePicker"
            defaultValue={dob && moment(dob)}
            disabledDate={(current) => {
              return moment().subtract(6, "years") < current;
            }}
            onChange={onChange}
          />

          <span className="text-red-600 mt-[6px] absolute">
            {dateErr && dateErr ? dateErr : ""}
          </span>
        </div>

        <div>
          <div className="lg:mt-2">
            <label
              className="font-primary capitalize text-sm lg:text-base"
              htmlFor="code"
            >
              Country Code Mobile Number
              <span className="text-red-600">*</span>
            </label>
          </div>
          <div className="mt-[0.5rem] h-[56px]">
            <PhoneInput
              placeholder="Enter Mobile number"
              value={value}
              onChange={setValue}
              className="mobile-number"
            />
            <span className="text-xs lg:ml-12 text-red-600 mt-[4px] absolute">
              {ErrMobile && ErrMobile ? ErrMobile : ""}
            </span>
          </div>
        </div>

        <div>
          <label
            className="font-primary capitalize text-sm lg:text-base"
            htmlFor="address"
          >
            Address <span className="text-red-600">*</span>
          </label>
          <textarea
            className="lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg"
            placeholder="Address"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            rows={5}
          ></textarea>
        </div>
        <div>
          <label
            className="font-primary capitalize text-sm lg:text-base"
            htmlFor="gender"
          >
            Select Gender <span className="text-red-600">*</span>
          </label>
          <div className="flex flex-row items-center mt-3 space-x-5">
            <div className="space-x-1 items-center">
              <input
                type="radio"
                onChange={(e) => setGender(e.target.value)}
                value="male"
                id="Male"
                checked={gender == "male" ? true : false}
                name="genderselect"
              />
              <label
                className="font-primary capitalize text-sm lg:text-base"
                htmlFor="Male"
              >
                Male
              </label>
            </div>
            <div className="space-x-1 items-center">
              <input
                type="radio"
                id="female"
                onChange={(e) => setGender(e.target.value)}
                value="female"
                checked={gender == "female" ? true : false}
                name="genderselect"
              />
              <label
                className="font-primary capitalize text-sm lg:text-base"
                htmlFor="female"
              >
                Female
              </label>
            </div>
          </div>
        </div>
        {/* userCV for student role */}
        {userDetails?.role == "Student" && (
          <>
            <div className="flex flex-col mt-3">
              <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                Upload CV
              </label>
              {coverImagePreview ? (
                <div className="h-44 border relative  mb-10 w-44">
                  <img
                    className="h-full w-44 p-1 rounded-lg object-cover object-center"
                    src={`${userCv?.split(".")?.pop() == "pdf" ? "/Assets/pdf_preview.png" : "/Assets/docx_preview.png"}`}
                    alt={"student cv"}
                  />
                  <div
                    onClick={(e) => {
                      deleteCoverImage(e, userDetails?.id);
                    }}
                    className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mx-auto text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <button type="submit" className="h-44">
                  <div className="items-center">
                    <label
                      data-testid="assessmentImage"
                      htmlFor="cv-file"
                      className="flex flex-col items-center justify-center w-44 h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm font-primary text-black dark:text-gray-400">
                          <span className="font-semibold font-primary">
                            Click to upload
                          </span>{" "}
                        </p>
                        <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                          PDF,DOCX (MAX Size. 5MB)
                        </p>
                      </div>
                      {userCv ? (
                        <div className="group w-44 h-40 -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black ">
                          <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm z-10 text-black dark:text-gray-400">
                              <span className="font-bold">Click to upload</span>{" "}
                            </p>
                          </div>
                          <div className="flex flex-row justify-center absolute group-hover:bg-black text-center w-full h-full rounded-xl  text-white">
                            <img
                              className="w-44 h-36 object-cover object-center rounded-xl"
                              src={`${userCv?.split(".")?.pop() == "pdf" ? "/Assets/pdf_preview.png" : "/Assets/docx_preview.png"}`}
                              alt={userCv}
                            />
                          </div>
                        </div>
                      ) : null}
                      <input
                        id="cv-file"
                        onChange={(e) => uploadCV(e)}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                </button>
              )}
              {progress > 0 && <Progress percent={progress} />}

              <p className="font-bold">{userCv?.split("/").pop()}</p>
            </div>{" "}
          </>
        )}

        {userDetails?.role == "Admin" && <ApiAccessToken email={email}/>}

        <div className="mb-6 mt-3">
          {userCv && (
            <>
              <label
                className="font-primary capitalize text-sm lg:text-base"
                htmlFor="gender"
              >
                Download CV
              </label>

              <Link
                className=" flex flex-row items-center w-40 gap-x-2 hover:bg-brand-600 bg-brand rounded text-white hover:text-white py-2 px-5 font-primary"
                to={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${userCv}`}
                download={userCv?.split("/").pop()}
              >
                Download CV{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>{" "}
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="mt-5">
        <PrimaryButton
          disabledStatus={formStatus}
          onclick={handleSubmit}
          text="Save"
          className={`${
            formStatus
              ? "bg-gray-300 cursor-not-allowed border-gray-300"
              : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
          }`}
        />
      </div>
    </div>
  );

  const Privacy = (
    <div className="relative mb-10 max-w-8xl flex flex-col lg:flex-col lg:space-x-5 space-x-2 h-full mx-auto bg-white shadow-xl p-3 lg:p-5 rounded-xl">
      <h1 className="text-text-xl lg:brand-500 text-xl lg:text-3xl lg:pl-5">
        Privacy Policy
      </h1>
      <p>
        We use your Personal data to provide and improve the Service. By using
        the Service, you agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <p>
        Last updated: January 4, 2023 <br />
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You. We use Your Personal data to provide and improve the Service. By
        using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <h1 className="text-brand-500 text-xl lg:text-3xl">Purchases</h1>
      <h4>Lemon Squeezy</h4>
      <p>
        Purchases made through the Website are processed by a third party
        payment processor, Lemon Squeezy (https://www.lemonsqueezy.com/). Lemon
        Squeezy may ask you for personal and/or non-personal information, such
        as your name, address, email address, credit card information, or other
        Personal Information. Lemon Squeezy has a privacy policy
        (https://www.lemonsqueezy.com/privacy) that describes their collection
        and use of personal information. We do not control Lemon Squeezy or its
        collection or use of information. Any questions or concerns about Lemon
        Squeezy's practices should be directed to Lemon Squeezy.
      </p>

      <h1 className="text-black-500 text-xl lg:text-3xl">Gumroad</h1>
      <p>
        Lemon Squeezy provides us with certain non-personal information relating
        to purchases made by visitors to the Website. The non-personal
        information may include details of the purchase such as the date, amount
        paid, and product purchased. The non-personal purchase information may
        be linked to the Personal Information you provide to us (typically
        limited to your email address, as stated above). Lemon Squeezy does not
        supply us with any of your other Personal Information such as your name,
        street address, or credit card information.
      </p>
    </div>
  );
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: basicInfotab,
      children: basicInfo,
    },
    {
      key: "2",
      label: accountEditText,
      children: Account,
    },
    {
      key: "3",
      label: privacyTab,
      children: Privacy,
    },
  ];

  return (
    <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 px-4">
      <div className="lg:my-4 my-2 lg:sticky">
        <p className="lg:text-2xl text-lg mb-0 pb-0 font-primary font-medium text-gray-800">
          Profile
        </p>

        <Breadcrumb>
          <Breadcrumb.Item>
            {userDetails.role == "Admin" ? (
              <Link to="/subadmin/AllDomains/list">Dashboard</Link>
            ) : userDetails.role == "SuperAdmin" ? (
              <Link to="/home/dashboard">Dashboard</Link>
            ) : (
              <Link to="/student/dashboard">Dashboard</Link>
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>Profile</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="  bg-white shadow-xl  rounded-xl">
        <div className=" p-2 lg:p-5">
          <div
            className=" bg-cover bg-top p-10 bg-no-repeat"
            style={{
              backgroundImage: `url("https://images.unsplash.com/photo-1611924707078-da8777fc99cb?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
            }}
          >
            <div className="w-full h-64  rounded-lg bg-black   bg-opacity-40 backdrop-blur-md">
              <img
                className="w-full h-full rounded-xl object-contain object-center"
                src={`${Logo}`}
                alt="user-icon"
              />
            </div>
          </div>

          {userDetails?.image != null ? (
            <div className="h-48 w-48 group -mt-16 z-50 relative bg-white p-1 rounded-xl border border-dashed border-black">
              <ImageField image={userDetails?.image} />
              <div
                onClick={(e) => deleteProfileImage(e, userUuid)}
                className="bg-black hover:bg-red-600 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mx-auto text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <>
              {" "}
              <button>
                <div className="flex z-50 relative items-center justify-center -mt-44 w-48">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                      </p>
                      <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                        PNG, JPG ,JPEG (MAX Size. 2MB)
                      </p>
                    </div>
                    <input
                      id="dropzone-file"
                      onChange={userProfileupload}
                      type="file"
                      className="hidden"
                    />
                  </label>
                </div>
              </button>
            </>
          )}
        </div>
        <div className="w-full px-3  lg:px-10">
          <Tabs
            className="profile-tabs"
            defaultActiveKey="1"
            size="large"
            items={items}
          />
        </div>
      </div>
    </section>
  );
};
