import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DashboardFooter } from "../../footer/AuthenticateFooter";
import { Link, useNavigate } from "react-router-dom";
import mcqImage from "../../../assets/images/mcq.png";
import settings from "../../../assets/images/settings.png";
import background from "../../../assets/images/background.webp";
import { allEvents } from "../../../serviceApi/adminApi/EventApi";
import { setCookie } from "../../../utils";
import PublicHeader from "../../../views/publicViews/pages/PublicHeader";
export const Landing = () => {
  const { loginWithRedirect } = useAuth0();
  const [events, setEvents] = useState<any>([]);
  const navigate = useNavigate();
  const handleRoute = (eventid) => {
    navigate(`/specificevent/${eventid}`);
  };
  const getAllevents = async () => {
    const res = await allEvents();
    if (res?.data?.events?.length > 0) {
      setEvents(JSON.parse(res?.data?.events[0]?.event));
    }
  };

  const handleEventRegister = (eventId) => {
    setCookie({ eventId }, 1800);
    loginWithRedirect({});
  };
  useEffect(() => {
    getAllevents();
  }, []);

  return (
    <>
      <main>
        <PublicHeader />

        {/* hero section start */}
        <section className="relative lg:h-full">
          <div className="max-w-screen-2xl relative mx-auto lg:mt-10 lg:p-0 p-4">
            {events?.length > 0 ? (
              events?.map((event) => {
                return (
                  <div
                    className="grid border rounded-lg lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 items-start gap-x-5 border-solid border-1 mt-1 p-3 lg:p-2 shadow-lg"
                    key={event.uuid}
                  >
                    <div className="lg:p-5">
                      <div className="flex flex-row">
                        <div>
                          <h1 className="lg:text-3xl lg:mb-5 mb-0 capitalize text-lg font-primary font-semibold text-black-700 text-black">
                            {" "}
                            {event?.name}{" "}
                          </h1>
                        </div>
                      </div>
                      <span className="text-lg lg:text-2xl font-primary font-extrabold text-brand-500">
                        {" "}
                        Online
                      </span>
                      <p className="text-lg lg:text-2xl font-primary font-extrabold text-black">
                        {" "}
                        Start Date
                        {" "}
                        {new Date(event.event_start_date).toUTCString()}
                      </p>

                      <div className="whitespace-pre-line break-words lg:mt-6 lg:text-normal text-justify  text-black lg:text-lg text-base  tracking-wide">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: event?.description,
                          }}
                          style={{ overflowWrap: "break-word" }}
                        ></div>
                      </div>
                      <div className="lg:mt-8 mt-4 flex flex-row space-x-5">
                        <p
                          onClick={(e) => handleEventRegister(event?.uuid)}
                          className="cursor-pointer rounded-lg text-sm lg:text-base py-2 lg:py-3 px-4 lg:px-8 font-primary font-semibold  text-white  hover:bg-blue-600 transition-transform duration-700 bg-blue-500 hover:text-white "
                        >
                          Register Now with Skillcef
                        </p>

                        <p
                          onClick={(e) => handleRoute(event?.uuid)}
                          className="cursor-pointer rounded-lg text-sm lg:text-base py-2 lg:py-3 px-4 lg:px-8 font-primary font-semibold  text-white  hover:bg-blue-600 transition-transform duration-700 bg-blue-500 hover:text-white "
                        >
                          See Details
                        </p>
                      </div>
                    </div>
                    <div className="lg:h-[750px] lg:p-10 mt-5">
                      <img
                        className="h-full w-full object-cover object-center"
                        alt="event-image"
                        src={`${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${event?.image}`}`}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <h1 className="font-black text-xl">
                  No events currently being hosted. Please check back later.
                </h1>
              </div>
            )}
          </div>
        </section>

        {/* quiz  section  start*/}
        <section className="bg-white py-8 lg:py-16">
          <div className="">
            <h1 className="text-3xl lg:text-7xl capitalize  my-0 py-0 font-primary text-center font-semibold text-black-700 text-gray-500">
              Quiz settings{" "}
              <span className="text-brand-500 font-bold">dashboard</span>
            </h1>
            <p className="lg:mt-5 lg:text-3xl text-lg text-center font-semibold text-black-700 text-gray-500">
              Manage quiz properties and options for the more efficient learning
              experience.
            </p>
            <div className="flex lg:ml-5 lg:mr-5  justify-center align-item-center ">
              <div className=" flex justify-center align-item-center ">
                <img
                  src={settings}
                  className="h-full w-full"
                  alt="setting image"
                />
              </div>
            </div>
          </div>
        </section>
        {/* quiz  section  start*/}

        {/* multiple question type start */}
        <section className="lg:py-16 py-8 bg-gray-100 ">
          <div className="max-w-screen-2xl mx-auto">
            <h1 className="text-3xl lg:text-7xl capitalize  my-0 py-0 font-primary text-center font-semibold text-black-700 text-gray-500">
              <span className="text-brand-500 font-bold">Multiple</span>{" "}
              question types
            </h1>
            <p className="lg:mt-5 mt-3  text-lg lg:text-3xl  text-center font-semibold text-black-700 text-gray-500">
              Add and combine the quizzes in your courses among the rich number
              of question formats.
            </p>
            <div className="lg:pt-8 p-4 grid lg:grid-cols-3 grid-cols-2 gap-4 lg:gap-10">
              <div className="lg:h-64 h-32">
                <img
                  src={mcqImage}
                  className="h-full w-full object-center object-cover"
                />
              </div>
              <div className="lg:h-64 h-32">
                <img
                  src={mcqImage}
                  className="h-full w-full object-center object-cover"
                />
              </div>
              <div className="lg:h-64 h-32">
                <img
                  src={mcqImage}
                  className="h-full w-full object-center object-cover"
                />
              </div>
            </div>
          </div>
        </section>
        {/* multiple question type start */}

        {/* signup section start */}
        <section>
          <div className="max-w-screen-2xl py-10 lg:py-16 mx-auto">
            <div className="relative px-2">
              <div>
                <img src={background} className="lg:h-[600px]  h-64 w-full" />
              </div>
              <div className="absolute top-5 lg:px-0 px-4 left-5 lg:top-20 lg:left-20 lg:max-w-4xl ">
                <h1 className="lg:text-5xl  text-lg lg:leading-[60px] lg:mt-10 font-primary capitalize   font-semibold text-white ">
                  Join with more than 25,000+ eLearning creators who've used and
                  loved Skillcef today!
                </h1>
                <p className="lg:mt-5 mt-2  text-sm lg:text-2xl  font-semibold  text-gray-300">
                  We offer a 15 day money-back guarantee along with a world
                  class community to help you get your courses online today!
                </p>
                <button
                  onClick={() => loginWithRedirect({})}
                  className="rounded-lg text-sm lg:text-base uppercase py-2 lg:py-3 px-4 lg:px-8 font-primary  text-black dark:text-white bg-white hover:text-black text-md"
                >
                  Signup
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* signup section start */}
      </main>
      <DashboardFooter />
    </>
  );
};
