import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import Swal from "sweetalert2";
import { getCookie } from "../../utils";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}

interface EventInput {
  name: string;
  description: string;
  address?: string;
  notification: boolean;
  userId: string;
  message?: string;
  event_type: any;
  speaker_details: string;
  event_start_date: string;
  event_end_date: string;
}

interface SpeakerInput {
  id?: any;
  speakerName: string;
  uuid?: string;
}

interface assessmenteventInput {
  eventId: any;
  assessmentId: any;
}

const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const fetchQuery = async (query, variables) => {
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err: any) {
    Swal.fire({
      icon: "error",
      title: "Something went wrong!",
      showConfirmButton: false,
      timer: 3000,
    });
    throw new Error(err);
  }
};

export const deleteEvent = async (eventId: string) => {
  const query = gql`
    query deleteEvent($eventId: String!) {
      deleteEvent(eventId: $eventId) {
        uuid
        id
        message
      }
    }
  `;
  const variables = {
    eventId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" Request DomainGqlQuery graphql error occurred", err);
  }
};

export const allEvents = async () => {
  const query = gql`
    query events {
      events {
        uuid
        name
        id
        notification
        description
        image
        userId
        message
        event_start_date
        event
        speakerDetails {
          speakerName
          id
        }
      }
    }
  `;

  try {
    const result = await client.query({
      query,
    });

    return result;
  } catch (err) {
    console.log("allEvents graphql error occurred", JSON.stringify(err));
  }
};

export const getEventsById = async (eventId: string) => {
  const query = gql`
    query getEventsById($eventId: String!) {
      getEventsById(eventId: $eventId) {
        uuid
        name
        id
        notification
        description
        image
        userId
        message
        event_start_date
        event_end_date
        event_type
        event
        speakerDetails {
          speakerName
          image
        }
      }
    }
  `;
  const variables = {
    eventId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" Request getEventsById graphql error occurred", err);
  }
};

export const getAcessTokenByUser = async (email: string) => {
  const query = gql`
    query getToken($email: String!) {
      getToken(email: $email) {
        uuid
        id
        accessToken
      }
    }
  `;
  const variables = {
    email,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" Request getEventsById graphql error occurred", err);
  }
};

export const getRegiterEventsById = async (eventId: string) => {
  const query = gql`
    query getRegiterEventsById($eventId: String!) {
      getRegiterEventsById(eventId: $eventId) {
        uuid
        name
        id
        notification
        description
        image
        userId
        message
        event_start_date
        event_end_date
        event_type
      }
    }
  `;
  const variables = {
    eventId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" Request getRegiterEventsById graphql error occurred", err);
  }
};
export const updateEvents = async (eventInput: EventInput) => {
  const updateEvent = gql`
    mutation updateEvents($eventInput: EventInput!) {
      updateEvents(eventInput: $eventInput) {
        uuid
        name
        event_type
        id
        notification
        description
        image
        userId
        message
        event_start_date
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: updateEvent,
      variables: { eventInput },
    });
    return result;
  } catch (err) {
    console.log("updateEvents graphql error occurred", JSON.stringify(err));
  }
};

export const addAssessmentEvents = async (
  assessmenteventInput: assessmenteventInput
) => {
  const addAssessmentevent = gql`
    mutation addAssessmentevent($assessmenteventInput: assessmenteventInput!) {
      addAssessmentevent(assessmenteventInput: $assessmenteventInput) {
        uuid
        id
        message
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: addAssessmentevent,
      variables: { assessmenteventInput },
    });
    return result;
  } catch (err) {
    console.log("updateEvents graphql error occurred", JSON.stringify(err));
  }
};

export const createEvent = async (eventInput: EventInput) => {
  const createEvent = gql`
    mutation createEvent($eventInput: EventInput!) {
      createEvent(eventInput: $eventInput) {
        uuid
        id
        name
        message
        description
        userId
        address
        image
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createEvent,
      variables: { eventInput },
    });
    return result;
  } catch (err) {
    console.log("createEvent graphql error occurred", err);
    return { data: err, status: 0 };
  }
};

export const createSpeakerDetail = async (speakerInput: SpeakerInput) => {
  const createSpeaker = gql`
    mutation createSpeaker($speakerInput: SpeakerInput!) {
      createSpeaker(speakerInput: $speakerInput) {
        uuid
        id
        speakerName
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createSpeaker,
      variables: { speakerInput },
    });
    return result;
  } catch (err) {
    console.log("createSpeaker graphql error occurred", err);
    return { data: err, status: 0 };
  }
};

export const eventRegistration = async (
  userId: string | undefined,
  name: string | undefined,
  eventId: string,
  userName: string
) => {
  const eventRegister = gql`
    mutation eventRegister(
      $userId: String!
      $name: String!
      $eventId: String!
      $userName: String!
    ) {
      eventRegister(
        userId: $userId
        name: $name
        eventId: $eventId
        userName: $userName
      ) {
        uuid
        id
        message
        userId
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: eventRegister,
      variables: { userId, name, eventId, userName },
    });
    return result;
  } catch (err) {
    console.log("eventRegistration for event graphql error occurred", err);
    return { data: err, status: 0 };
  }
};

export const getEvents = async (email: string) => {
  const query = gql`
    query getEvents($email: String!) {
      getEvents(userId: $email) {
        id
        name
        description
        uuid
        createdAt
        image
        event_end_date
        event_start_date
      }
    }
  `;

  const variables = {
    email,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

//get events by filter
export const getEventFilterData = async (eventStatus: string, date: any) => {
  const query = gql`
    query getEventFilter($eventStatus: String!, $date: String!) {
      getEventFilter(eventStatus: $eventStatus, date: $date) {
        id
        name
        description
        uuid
        createdAt
        image
        event_end_date
        event_start_date
      }
    }
  `;

  const variables = {
    eventStatus,
    date,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getAdminEvents = async (email: string) => {
  const query = gql`
    query getAdminEvents($email: String!) {
      getAdminEvents(userId: $email) {
        uuid
        name
        id
        notification
        description
        image
        userId
        message
        event_start_date
        event_end_date
        event_type
      }
    }
  `;

  const variables = {
    email,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getEventRegisterDetail = async (
  userId: string | undefined,
  assessmentId: string | undefined,
  eventId: string | undefined
) => {
  const query = gql`
    query eventRegisterUser(
      $userId: String!
      $assessmentId: String!
      $eventId: String!
    ) {
      eventRegisterUser(
        userId: $userId
        assessmentId: $assessmentId
        eventId: $eventId
      ) {
        uuid
        id
        name
        description
        image
        userId
      }
    }
  `;
  const variables = {
    userId,
    assessmentId,
    eventId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getEventPrivateAssessment = async (userId: string | undefined) => {
  const query = gql`
    query getUserEventAssessment($userId: String!) {
      getUserEventAssessment(userId: $userId) {
        uuid
        id
        name
        description
        image
        userId
        type
        eventStartDate
        eventEndDate
      }
    }
  `;
  const variables = {
    userId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const checkUserEventAssessment = async (
  userId: string | undefined,
  assessmentId: string | undefined
) => {
  const query = gql`
    query checkEventAssessment($userId: String!, $assessmentId: String!) {
      checkEventAssessment(userId: $userId, assessmentId: $assessmentId) {
        uuid
        id
        name
        description
        image
        userId
        type
        eventStartDate
        eventEndDate
      }
    }
  `;
  const variables = {
    userId,
    assessmentId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};
