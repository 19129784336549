import { useEffect, useState } from "react";
import { createToken } from "../../../../serviceApi/api";
import { getAcessTokenByUser } from "../../../../serviceApi/adminApi/EventApi";

export default function ApiAccessToken({ email }) {
  const [token, setToken] = useState("");
  const generateToken = async () => {
    const result = await createToken(email);
    setToken(result?.data?.createToken?.accessToken);
  };

  // Copy token to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    alert("Token copied to clipboard!");
  };

  const getAccessToken = async () => {
    const result = await getAcessTokenByUser(email);
    setToken(result?.data?.getToken[0]?.accessToken);
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <div>
      <div className="flex items-center space-x-2">
        <textarea
          value={token}
          readOnly
          placeholder="Your token will appear here"
          className="text-wrap lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full h-20 rounded-lg"
        />
      </div>

      {token ? (
        <button
          onClick={copyToClipboard}
          disabled={!token}
          className="w-full outline mt-2"
        >
          <span className="w-5 h-5 mr-2 mt-2">Copy Token</span>
        </button>
      ) : (
        <button onClick={generateToken} className="w-full outline mt-2">
          <span className="w-5 h-5 mr-2 mt-2"> Generate Token</span>
        </button>
      )}
    </div>
  );
}
