import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import { getCookie } from "../../utils";
import Swal from "sweetalert2";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}

interface QuestionAnswerInput {
  assessmentId: string | undefined;
  questionId: string | undefined;
  question?: string | undefined | null;
  answers?: any;
  weightage: string;
  CorrectAnswer?: [string] | undefined | null;
  topic: string | undefined;
  questionLevel: string | undefined;
  questionType?: string | undefined;
  action: string;
}

interface Code {
  html: string;
  css: string;
  jsCode: string;
}

interface StudentQuestionAnswerInput {
  questionId?: number;
  answers: string;
  userId: number;
  assessmentId: string | undefined;
  language: string | undefined;
  topic: string | undefined;
}

interface AssessmentInput {
  name: string;
  duration?: string;
  skillId: string;
  assessmentId: string;
  userId: string;
  expire?: string;
  url: string;
  description: string;
  assessmentType: string;
}

interface FavouriteAssessmentsInput {
  assessmentId: string;
  userId: string;
}
interface UserInput {
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  role?: string;
  dob?: Date;
  Contact_number?: string;
  Address?: string;
}

interface StudentInput {
  userId: string;
  firstName: string;
  dob: string;
  lastName: string;
  password: string;
}

interface UserUpdate {
  firstName: string;
  lastName: string;
  Contact_number: string;
  dob: string;
  Address: string;
  gender: string;
  userId: string;
  code: string;
}

interface addAnswersListType {
  answerId: string;
  answer: string | any;
  questionId?: string | number;
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const fetchQuery = async (query, variables) => {
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err: any) {
    console.log("err", err);
    throw new Error(err);
  }
};

export const getAssessments = async (
  skillId: string | undefined,
  userId: string
) => {
  const query = gql`
    query assessments($skillId: ID!, $userId: ID!) {
      assessments(skillId: $skillId, userId: $userId) {
        id
        uuid
        name
        duration
        expire
        active
        description
        image
        url
        userId
        type
      }
    }
  `;
  const variables = {
    skillId,
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getAllAssessments = async (skillId: any, userId: any) => {
  const query = gql`
    query allAssessments($skillId: ID!, $userId: ID!) {
      allAssessments(skillId: $skillId, userId: $userId) {
        id
        uuid
        name
        duration
        expire
        active
        description
        image
        url
        userId
        type
      }
    }
  `;
  const variables = {
    skillId,
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getTopperList = async (assessmentId: string) => {
  const query = gql`
    query courseResult($assessmentId: String!) {
      getTopperList(assessmentId: $assessmentId) {
        id
        score
        firstName
        image
        userId
        finalPer
      }
    }
  `;
  const variables = {
    assessmentId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getQuestions = async (assessmentId: string | undefined) => {
  const query = gql`
    query questions($assessmentId: ID!) {
      questions(assessmentId: $assessmentId) {
        id
        question
        option
        questionType
        answers
      }
    }
  `;
  const variables = {
    assessmentId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const totalAssessment = async () => {
  const query = gql`
    query assessmentsNumber {
      assessmentsNumber {
        id
        name
        duration
        expire
        active
        type
        image
        url
        description
        uuid
        status
        assessmentid
        userId
      }
    }
  `;
  try {
    const request = await client.query({
      query,
    });
    return request;
  } catch (err) {
    return { data: err, status: 0 };
  }
};

export const invitationAssessment = async (userId: string) => {
  const query = gql`
    query invitationAssesment($userId: String!) {
      invitationAssesment(userId: $userId) {
        uuid
        id
        status
        expiryDate
        assessmentId
        emailId
      }
    }
  `;
  const variables = {
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const checkAuthenticate = async (userId: any, assessmentId: string) => {
  const query = gql`
    query checkAuthenticate($userId: String!, $assessmentId: String!) {
      checkAuthenticate(userId: $userId, assessmentId: $assessmentId) {
        uuid
        id
        status
        expiryDate
        assessmentId
        emailId
      }
    }
  `;
  const variables = {
    userId,
    assessmentId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getQuestionOptionDetail = async (
  questionId: number,
  assessmentId: string | undefined,
  userId: number
) => {
  const query = gql`
    query questionOptionDetail($questionId: ID!, $userId: ID!) {
      questionOptionDetail(questionId: $questionId, userId: $userId) {
        id
        answers
      }
    }
  `;
  const variables = {
    questionId,
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getQuestionDetails = async (
  assessmentId: any,
  questionId: any,
  questionType: string
) => {
  const query = gql`
    query getQuestionDetails(
      $assessmentId: ID!
      $questionId: ID!
      $questionType: ID!
    ) {
      getQuestionDetails(
        assessmentId: $assessmentId
        questionId: $questionId
        questionType: $questionType
      ) {
        id
        questionId
        uuid
        question
        correctAnswers
        Topic
        weightage
        answers
        complexity
      }
    }
  `;
  const variables = {
    assessmentId,
    questionId,
    questionType,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getAssessmentNameById = async (
  assessmentid: string | undefined
) => {
  const query = gql`
    query assessmentNameById($assessmentid: ID!) {
      assessmentNameById(assessmentid: $assessmentid) {
        id
        name
        duration
        expire
        active
        description
        url
        image
        type
      }
    }
  `;
  const variables = {
    assessmentid,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getDomainRequestNameById = async (
  domainId: string,
  userId: string
) => {
  const query = gql`
    query requestDomainNameById($domainId: ID!,$userId:userId) {
      requestDomainNameById(domainId: $domainId:userId:$userId) {
        id
        uuid
        name
        status
      }
    }
  `;
  const variables = {
    domainId,
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getSkillNameBySkillId = async (skillId: string | undefined) => {
  const query = gql`
    query skillNameById($skillId: ID!) {
      skillNameById(skillId: $skillId) {
        id
        name
        categoryId
        uuid
        description
        image
      }
    }
  `;
  const variables = {
    skillId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getUsers = async (email: string) => {
  const query = gql`
    query User($email: String) {
      user(userId: $email) {
        userId
        id
        uuid
        Contact_number
        dob
        Address
        image
        gender
        firstName
        lastName
        role
        language
        # cv
      }
    }
  `;

  const variables = {
    email,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getCv = async (uuid: string) => {
  const query = gql`
    query getCv($uuid: String!) {
      getCv(uuid: $uuid) {
        userId
        id
        uuid
        cv
      }
    }
  `;

  const variables = {
    uuid,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getStatus = async (
  email: string,
  assessmentId: string | undefined
) => {
  const query = gql`
    query getStatus($email: String!, $assessmentId: String!) {
      getStatus(userId: $email, assessmentId: $assessmentId) {
        id
        firstName
        lastName
        assessmentName
        score
        finalPer
        status
        totalWeightage
        length
        topic
        userId
      }
    }
  `;

  const variables = {
    email,
    assessmentId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getScore = async (email: any) => {
  const query = gql`
    query getScore($email: String!) {
      getScore(userId: $email) {
        id
        score
        status
        assessmentName
        finalPer
        assessmentId
        totalWeightage
        createdAt
        number
        date
      }
    }
  `;

  const variables = {
    email,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getChartData = async (email: any, filter: any) => {
  const query = gql`
    query getStudentChartData($email: String!, $filter: String!) {
      getStudentChartData(userId: $email, filter: $filter) {
        id
        score
        status
        assessmentName
        finalPer
        assessmentId
        totalWeightage
        createdAt
        number
        date
        studentId
      }
    }
  `;

  const variables = {
    email,
    filter,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getAllStudentsResultData = async (email: any) => {
  const query = gql`
    query getStudentCoursesDataforRecommendation($email: String!) {
      getStudentCoursesDataforRecommendation(userId: $email) {
        id
        score
        status
        assessmentName
        finalPer
        assessmentId
        totalWeightage
        createdAt
        number
        date
        studentId
        topic
      }
    }
  `;

  const variables = {
    email,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getUserScoreForRewards = async (email: any, assessmentId: any) => {
  const query = gql`
    query checkScoreForRewards($email: String!, $assessmentId: String!) {
      checkScoreForRewards(userId: $email, assessmentId: $assessmentId) {
        id
        score
        status
        assessmentName
        finalPer
        assessmentId
        totalWeightage
        createdAt
        number
        date
      }
    }
  `;

  const variables = {
    email,
    assessmentId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getResult = async (
  email: string | undefined,
  assessmentId: string | undefined
) => {
  const query = gql`
    query getResult($email: String!, $assessmentId: String!) {
      getResult(userId: $email, assessmentId: $assessmentId) {
        id
      }
    }
  `;
  const variables = {
    email,
    assessmentId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const questionAnswerCreate = async (
  questionAnswerInput: QuestionAnswerInput
) => {
  const createQuestionAnswer = gql`
    mutation createQuestionAnswer($questionAnswerInput: QuestionAnswerInput!) {
      createQuestionAnswer(questionAnswerInput: $questionAnswerInput) {
        uuid
        id
        option
        questionId
      }
    }
  `;
  try {
    const response = await client.mutate({
      mutation: createQuestionAnswer,
      variables: { questionAnswerInput },
    });
    return response;
  } catch (err) {
    console.log("createQuestion graphql error occurred", JSON.stringify(err));
    return { status: 0, data: err };
  }
};

export const saveQuestionAnswer = async (
  studentQuestionAnswerInput: StudentQuestionAnswerInput
) => {
  const createStudentQuestionAnswer = gql`
    mutation createStudentQuestionAnswer(
      $studentQuestionAnswerInput: StudentQuestionAnswerInput!
    ) {
      createStudentQuestionAnswer(
        studentQuestionAnswerInput: $studentQuestionAnswerInput
      ) {
        uuid
        id
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createStudentQuestionAnswer,
      variables: { studentQuestionAnswerInput },
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getOptions = async (questionId: string | undefined) => {
  const query = gql`
    query quesionsOptions($questionId: ID!) {
      quesionsOptions(questionId: $questionId) {
        uuid
        option
      }
    }
  `;
  const variables = {
    questionId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getOptionsByUuid = async (uuid: string) => {
  const query = gql`
    query quesionsOptionsByUuid($uuid: ID!) {
      quesionsOptionsByUuid(uuid: $uuid) {
        uuid
        option
      }
    }
  `;
  const variables = {
    uuid,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const deleteQuestion = async (uuid: string, table: string) => {
  const deleteQuestion = gql`
    mutation questionDelete($uuid: String!, $table: String!) {
      questionDelete(uuid: $uuid, table: $table) {
        id
        message
        question
        option
      }
    }
  `;

  const variables = {
    uuid,
    table,
  };

  try {
    const request = await client.mutate({
      mutation: deleteQuestion,
      variables,
    });
    return request;
  } catch (err) {}
};

export const insertUserWallet = async (userId: string) => {
  const insertUserWallet = gql`
    mutation wallet($userId: String!) {
      wallet(userId: $userId) {
        uuid
        id
        message
        balance
      }
    }
  `;

  const variables = {
    userId,
  };

  try {
    const request = await client.mutate({
      mutation: insertUserWallet,
      variables,
    });
    return request;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteQuestionData = async () => {
  const query = gql`
    query deleteData {
      deleteData {
        uuid
        id
        question
        weightage
      }
    }
  `;
  try {
    const request = await client.query({
      query,
    });
    return request;
  } catch (err) {
    return { data: err, status: 0 };
  }
};

export const previousAnswersDelete = async (
  assessmentId: string | undefined,
  userId: number
) => {
  const DeletePreviousAnswers = gql`
    mutation DeletePreviousAnswers($assessmentId: String!, $userId: ID!) {
      DeletePreviousAnswers(assessmentId: $assessmentId, userId: $userId) {
        id
      }
    }
  `;

  const variables = {
    assessmentId,
    userId,
  };

  try {
    const request = await client.mutate({
      mutation: DeletePreviousAnswers,
      variables,
    });
    return request;
  } catch (err: any) {
    throw new Error(err);
  }
};
export const createAssessment = async (assessmentInput: AssessmentInput) => {
  const createAssessment = gql`
    mutation createAssessment($assessmentInput: AssessmentInput!) {
      createAssessment(assessmentInput: $assessmentInput) {
        uuid
        id
        name
        duration
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createAssessment,
      variables: { assessmentInput },
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const createfavouriteAssessment = async (
  favouriteAssessmentsInput: FavouriteAssessmentsInput
) => {
  const createfavouriteAssessment = gql`
    mutation createfavouriteAssessment(
      $favouriteAssessmentsInput: FavouriteAssessmentsInput!
    ) {
      createfavouriteAssessment(
        favouriteAssessmentsInput: $favouriteAssessmentsInput
      ) {
        id
        name
        description
        uuid
        status
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createfavouriteAssessment,
      variables: { favouriteAssessmentsInput },
    });
  } catch (err) {
    console.log("createCategory graphql error occurred", err);
  }
};

export const createUsers = async (userInput: UserInput) => {
  const createUsers = gql`
    mutation createUsers($userInput: UserInput!) {
      createUsers(userInput: $userInput) {
        lastName
        firstName
        userId
        role
        uuid
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createUsers,
      variables: { userInput },
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const createStudents = async (studentInput: StudentInput) => {
  const createStudents = gql`
    mutation createQuestion($studentInput: StudentInput!) {
      createQuestion(questionInput: $questionInput) {
        userId
      }
    }
  `;

  try {
    return await client.mutate({
      mutation: createStudents,
      variables: { studentInput },
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const assesmentCreate = async (assessmentInput: AssessmentInput) => {
  const assesmentCreate = gql`
    mutation createAssessment($assessmentInput: AssessmentInput!) {
      createAssessment(assessmentInput: $assessmentInput) {
        uuid
        id
        status
        message
      }
    }
  `;

  try {
    const result = await client.mutate({
      mutation: assesmentCreate,
      variables: { assessmentInput },
    });
    return result;
  } catch (err) {
    console.log("create Assesment graphql error occurred", JSON.stringify(err));
  }
};

export const students = async (userId: string) => {
  const query = gql`
    query students($userId: String!) {
      students(userId: $userId) {
        userId
        id
        Contact_number
        dob
        Address
        gender
        firstName
        lastName
      }
    }
  `;
  const variables = {
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getWalletDetails = async (userId: string | any) => {
  const query = gql`
    query getWallet($userId: ID!) {
      getWallet(userId: $userId) {
        userId
        id
        uuid
        balance
      }
    }
  `;
  const variables = {
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};



export const getCode = async (shareId: string | any) => {
  const query = gql`
    query getHtmlCode($shareId: String!) {
      getHtmlCode(shareId: $shareId) {
        shareId
        code
      }
    }
  `;
  const variables = {
    shareId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};
export const htmlCodeSaved = async (shareId: string | undefined, code: string) => {
  const codeSaved = gql`
    mutation codeSaved($shareId: String!, $code: String!) {
      codeSaved(shareId: $shareId, code: $code) {
       message
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: codeSaved,
      variables: { shareId, code },
    });
    return result;
  } catch (err) {
    return { data: err, status: 0 };
  }
};

export const registerStudents = async (studentInput: StudentInput) => {
  const createStudents = gql`
    mutation createStudents($studentInput: StudentInput!) {
      createStudents(studentInput: $studentInput) {
        userId
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createStudents,
      variables: { studentInput },
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updateUser = async (userUpdate: UserUpdate) => {
  const updateUser = gql`
    mutation updateUser($userUpdate: UserUpdate!) {
      updateUser(userUpdate: $userUpdate) {
        userId
        id
        Contact_number
        dob
        Address
        gender
        firstName
        lastName
        message
        code
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: updateUser,
      variables: { userUpdate },
    });
    return result;
  } catch (err: any) {
    Swal.fire({
      icon: "error",
      title: "Something went wrong!",
      showConfirmButton: false,
      timer: 3000,
    });
    throw new Error(err);
  }
};

//add answer(option for mcq type questions)
export const addAnswersList = async (addAnswersList: addAnswersListType) => {
  const createAnswersList = gql`
    mutation createAnswersList($addAnswersList: addAnswersList!) {
      createAnswersList(addAnswersList: $addAnswersList) {
        uuid
        option
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createAnswersList,
      variables: { addAnswersList },
    });
  } catch (err: any) {
    Swal.fire({
      icon: "error",
      title: `Something went wrong!` + err?.message,
      showConfirmButton: false,
      timer: 3000,
    });
    return { status: 0, data: err };
  }
};
