import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  deleteCookieToken,
  getCookie,
  parseJwt,
  setCookie,
} from "../../../../utils/index";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { createUsers, getUsers } from "../../../../serviceApi/api";
import { nameInputValid } from "../../../../utils/validation";
import { loginContext } from "../../../../components/context/LoginContext";
import type { DatePickerProps } from "antd";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";
import moment from "moment";
import { InputField } from "../../../../components/inputFields/InputField";
import { Spin, DatePicker } from "antd";
export const UserProfile = () => {
  const navigate = useNavigate();
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const email = user?.email;
  const { role, setRole }: any = useContext(loginContext);
  const [userEmail, setUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [status, setStatus] = useState(true);
  const [firstNameErr, setFirstnameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [address, setAddress] = useState("");
  const [value, setValue] = useState<any>();
  const [ErrMobile, setErrMobile] = useState("");
  const [dob, setDob] = useState<any>(null);
  const [, setMobile] = useState("");
  const [dateErr, setDateErr] = useState("");
  const [gender, setGender] = useState("");
  const [existRole, setExistRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [apiDataLoader, setApiDataLoader] = useState(false);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setDob(dateString);
  };
  if (
    document.cookie === undefined ||
    document.cookie === null ||
    document.cookie === ""
  ) {
    navigate("/");
  }
  const datasubmit = async () => {
    setStatus(true);
    setApiDataLoader(true);
    const userData = {
      firstName,
      lastName,
      email: user?.email,
      role,
      Address: address,
      Contact_number: value,
      gender,
      dob,
    };
    const Users = await createUsers(userData);
    if (Users?.data.createUsers?.role !== null) {
      setApiDataLoader(false);
      if (Users?.data.createUsers?.role === "Admin") {
        navigate("/subadmin/AllDomains/list");
      } else if (Users?.data.createUsers?.role === "Student") {
        navigate(`/student/profile/${Users?.data.createUsers.uuid}/skills`);
      }
    } else {
      setStatus(false);
      setApiDataLoader(false);
      Swal.fire({
        icon: "warning",
        title: "Invalid data please remove symbols.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleInputs = (e: any) => {
    if (e.target.name == "mobile_number") {
      if (e.target.value.length > 10 || e.target.value.length < 10) {
        setErrMobile("Mobile Must be 10 charactors");
        setMobile("");
      } else {
        setErrMobile("");
        setMobile(e.target.value);
      }
    }
  };

  const time: any = new Date();
  const mondDiff = time - new Date(dob)?.getTime();
  const age_dt = new Date(mondDiff);
  const year = age_dt.getUTCFullYear();
  const age = Math.abs(year - 1970);

  useEffect(() => {
    if (
      (role == "Admin" || role == "Student") &&
      firstName?.trim()?.length > 0 &&
      lastName?.trim()?.length > 0 &&
      gender
    ) {
      if (value && isValidPhoneNumber(value.toString())) {
        setStatus(false);
        setErrMobile("");
      } else {
        setStatus(true);
        setErrMobile("Please Enter valid number ");
      }
    } else {
      setStatus(true);
      if (value?.length > 0 && isValidPhoneNumber(value.toString())) {
        setErrMobile("");
      } else {
        setStatus(true);
        setErrMobile("Please Enter valid number ");
      }
    }
  }, [role, firstName, lastName, value, gender]);

  const validateUserRole = async (email: any) => {
    if ((email && email != "") || email != null) {
      const userRole = await getUsers(email);
      if (userRole?.data?.user) {
        setExistRole(userRole?.data?.user[0]?.role);
        setRole(userRole?.data?.user[0]?.role);
        setIsLoading(false);
        // window.localStorage.setItem("role", userRole?.data?.user[0]?.role);
      } else {
        setExistRole("new user");
        setRole("new user");
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    getToken();
    if ((email && email != "") || email != null) {
      validateUserRole(email);
    }
  }, [email, user]);

  let tokenTimes: any = {};

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        // audience: `https://${domain}/api/v2/`,
        // scope: "read:current_user",
      });
      setCookie({ token });
    } catch (err) {
      logout({});
    }
  };

  const parseToken = () => {
    const token = getCookie("token");

    if (!token) {
      return;
    }
    const jsonToken = parseJwt(token);
    const { iat, exp } = jsonToken;

    const timeIat = iat * 1000;
    const timeExp = exp * 1000;

    return {
      timeIat,
      timeExp,
    };
  };

  useLayoutEffect(() => {
    if (email) {
      setUserEmail(email);
    }
  }, [user]);

  useEffect(() => {
    setTimeout(() => initTimer(), 2000);
  }, []);

  useLayoutEffect(() => {
    if (existRole === "SuperAdmin") {
      navigate("/home/dashboard");
    } else if (existRole === "Admin") {
      navigate("/subadmin/AllDomains/list");
    } else if (existRole === "Student") {
      navigate("/student/dashboard");
    } else if (existRole === "new user") {
      navigate("/profile");
    }
  }, [existRole]);

  const initTimer = () => {
    getToken();
    tokenTimes = parseToken();

    if (tokenTimes?.timeIat && tokenTimes?.timeExp) {
      const interval = setInterval(async () => {
        new Date(1663492442000);

        if (interval) {
          clearInterval(interval);
          setTimeout(() => initTimer(), 0);
        }
      }, Math.abs(tokenTimes?.timeIat - tokenTimes?.timeExp));
    } else {
      setTimeout(() => initTimer(), 0);
    }
  };

  const handleInput = (e: any) => {
    if (nameInputValid(e.target.value)) {
      if (e.target.name === "first_name") {
        setFirstName(e.target.value);
        setFirstnameErr("");
      }

      if (e.target.name === "last_name") {
        setLastName(e.target.value);
        setLastNameErr("");
      }
    } else {
      if (e.target.name === "first_name") {
        setFirstnameErr("First name should be characters only");
        setFirstName("");
      }

      if (e.target.name === "last_name") {
        setLastNameErr("Last name should be characters only");
        setLastName("");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <section>
          <div className="relative lg:h-[28rem] w-full">
            <img
              className="object-cover float-center ml-48"
              src="https://cdni.iconscout.com/illustration/premium/thumb/man-checking-email-in-laptop-2706014-2255345.png"
              alt=""
            />
          </div>
          <div className="px-4 py-10 relative rounded-xl -mt-24 shadow-xl bg-white h-full  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8">
            <p className="text-4xl my-0 text-black text-center font-bold ">
              SkillCef
            </p>

            <div className="bg-brand-500 h-0.5 w-32 my-2 mx-auto text-center rounded-full"></div>
            <div>
              <p className="text-3xl px-16 py-0 font-primary my-4 text-brand-500 font-semibold">
                Please complete your profile
              </p>
              {status ? (
                <p className="px-16 text-red-600 my-0 py-0 text-sm">
                  All fields are required
                </p>
              ) : (
                <p className="px-16 text-green-800 my-0 py-0 text-sm">
                  All fields are Completed
                </p>
              )}

              <div className="px-16  py-5">
                <p className="my-0 py-0 font-primary text-black capitalize">
                  Select your role{" "}
                  <span className="text-red-600 text-lg">*</span>
                </p>
                <div className="w-full grid lg:grid-cols-6 items-center justify-center content-center">
                  <div className="flex items-center   bg-white ">
                    <input
                      id="students"
                      onChange={(e) => setRole(e.target.value)}
                      type="radio"
                      value="Student"
                      name="default-radio"
                      className="w-6 h-5  rounded-full"
                      required
                    />
                    <label
                      htmlFor="students"
                      className="ml-2 font-primary   w-full text-lg bg-white py-3 text-gray-900"
                    >
                      Student
                    </label>
                  </div>

                  <div className="flex items-center bg-white ">
                    <input
                      id="admin"
                      type="radio"
                      value="Admin"
                      onChange={(e) => setRole(e.target.value)}
                      name="default-radio"
                      className="w-6 h-6"
                    />
                    <label
                      htmlFor="admin"
                      className="ml-2 font-primary  w-full text-lg bg-white py-3 text-gray-900"
                    >
                      Admin
                    </label>
                  </div>
                </div>
                <div className="grid gap-x-16 gap-y-5 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      First name
                      <span className="text-red-600 text-lg ml-1">*</span>
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      onChange={(e) => handleInput(e)}
                      autoComplete="off"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                      placeholder="First name"
                      required
                    />
                    <span className="text-red-600">
                      {firstNameErr && firstNameErr ? firstNameErr : ""}
                    </span>
                  </div>
                  <div>
                    <label
                      htmlFor="last_name"
                      className="block  text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Last name <span className="text-red-600 text-lg">*</span>
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      onChange={(e) => handleInput(e)}
                      autoComplete="off"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                      placeholder="Last name"
                      required
                    />
                    <span className="text-red-600">
                      {lastNameErr && lastNameErr ? lastNameErr : ""}
                    </span>
                  </div>

                  <div>
                    <label
                      htmlFor="last_name"
                      className="font-primary capitalize text-sm lg:text-base"
                    >
                      D.O.B (optional)
                    </label>
                    <DatePicker
                      className="userProfileDatePicker"
                      disabledDate={(current) => {
                        return moment().subtract(6, "years") < current;
                      }}
                      onChange={onChange}
                    />

                    <span className="text-red-600 mt-[6px] absolute">
                      {dateErr && dateErr ? dateErr : ""}
                    </span>
                  </div>

                  <div>
                    <label
                      className="font-primary capitalize text-sm lg:text-base mb-5 mt-2"
                      htmlFor="code"
                    >
                      Country Code Mobile Number
                      <span className="text-red-600">*</span>
                    </label>
                    <div className="mt-2">
                      <PhoneInput
                        placeholder="Enter Mobile number"
                        value={value}
                        onChange={setValue}
                      />
                      <span className="text-xs lg:ml-12 text-red-600 mt-[4px] absolute">
                        {ErrMobile && ErrMobile ? ErrMobile : ""}
                      </span>
                    </div>
                  </div>
                  <div>
                    <label
                      className="font-primary capitalize text-sm lg:text-base"
                      htmlFor="address"
                    >
                      Address (optional)
                    </label>
                    <textarea
                      className="lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg"
                      placeholder="Address"
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      rows={5}
                    ></textarea>
                  </div>
                  <div className="mt-2">
                    <label
                      className="font-primary capitalize text-sm lg:text-base"
                      htmlFor="gender"
                    >
                      Select Gender <span className="text-red-600">*</span>
                    </label>
                    <div className="flex flex-row items-center mt-3 space-x-5">
                      <div className="space-x-1 items-center">
                        <input
                          type="radio"
                          onChange={(e) => setGender(e.target.value)}
                          value="male"
                          id="Male"
                          checked={gender == "male" ? true : false}
                          name="genderselect"
                        />
                        <label
                          className="font-primary capitalize text-sm lg:text-base"
                          htmlFor="Male"
                        >
                          Male
                        </label>
                      </div>
                      <div className="space-x-1 items-center">
                        <input
                          type="radio"
                          id="female"
                          onChange={(e) => setGender(e.target.value)}
                          value="female"
                          checked={gender == "female" ? true : false}
                          name="genderselect"
                        />
                        <label
                          className="font-primary capitalize text-sm lg:text-base"
                          htmlFor="female"
                        >
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <input
                      type="email"
                      id="email"
                      value={userEmail}
                      readOnly
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                      placeholder="email"
                      required
                    />
                  </div>
                </div>
                <button
                  onClick={datasubmit}
                  type="submit"
                  disabled={status ? true : false}
                  className={`px-8  py-2 mt-5 text-white text-lg bg-green-600 ${
                    status ? "opacity-40 cursor-not-allowed" : "opacity-100"
                  } rounded-md focus:outline-none`}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {apiDataLoader && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Spin tip="Loading..."></Spin>
            </div>
          )}
        </section>
      )}
    </>
  );
};
