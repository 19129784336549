import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { getScore } from "../../../../serviceApi/api";
import { Breadcrumb } from "antd";
import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import PaginationData from "../../../../components/pagination/Pagination";

export const StudentResult = () => {
  const { user } = useAuth0();
  const email: string | any = user as string | any;
  const [studentResult, setStudentResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [, setActive] = useState(false);
  const [input, setInput] = useState("");

  const getResultDetails = async (email) => {
    const resultInfo = await getScore(email?.email);
    if (resultInfo?.data?.getScore) {
      const resultData = resultInfo?.data?.getScore;
      const newArray = resultData.map((m) => [m.assessmentName, m]);
      const newMap = new Map(newArray);
      const iterator: any = newMap.values();
      const uniqueResult: any = [...iterator];
      setStudentResult(uniqueResult);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getResultDetails(email);
  }, [email]);

  return (
    <div>
      {isLoading ? (
        <section className="w-full mt-5 lg:px-5 px-4">
          <div className="grid grid-cols-4 lg:grid-cols-4 gap-8 w-full ">
            <Fragment>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
            </Fragment>
          </div>
        </section>
      ) : (
        <>
          <section className="w-full max-h-[84vh] h-screen mt-5 overflow-y-auto lg:p-10 p-5">
            <div className="px-4 lg:py-4 h-full w-full">
              <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
                Result
              </p>
              <div className="lg:mb-4">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/student/dashboard">Dashboard</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Result</Breadcrumb.Item>
                </Breadcrumb>
              </div>

              {paginatedData && paginatedData.length > 0 ? (
                <div className="grid lg:grid-cols-4 gap-x-10 gap-y-5">
                  {paginatedData?.length > 0 &&
                    paginatedData?.map((result: any, i: number) => (
                      <Fragment key={i}>
                        <Link
                          to={`/student/result/assessment/${result.assessmentId}`}
                        >
                          <div
                            key={result.assessmentId}
                            className="bg-white h-full shadow-xl p-5 lg:mt-5 rounded-xl"
                          >
                            <div>
                              <div className=" flex flex-row items-center justify-between ">
                                <p className="lg:text-lg text-base capitalize text-brand-600 font-semibold text-center my-0">
                                  <span className="text-brand-600 font-primary">
                                    Assessment name : {result.name}
                                  </span>
                                </p>
                              </div>

                              <p className="questiontext text-lg lg:text-xl font-semibold space-x-10 flex mt-2 lg:mt-4 border-t  pt-2">
                                <span className=" text-black font-primary">
                                  Status :{" "}
                                  <span className="text-blue-500">
                                    {result.status}
                                  </span>
                                </span>
                              </p>
                              <div className="text-sm lg:text-base text-center capitalize  rounded-xl text-white font-primary bg-brand-500 p-1 lg:p-2">
                                view more
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Fragment>
                    ))}
                </div>
              ) : (
                <div className=" bg-white shadow-lg p-5 max-w-2xl mx-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 256 256"
                    className="text-center text-green-600 h-24 w-24 mx-auto"
                  >
                    <rect width="256" height="256" fill="none"></rect>
                    <circle
                      cx="128"
                      cy="128"
                      r="96"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="16"
                    ></circle>
                    <circle cx="92" cy="108" r="12"></circle>
                    <circle cx="164" cy="108" r="12"></circle>
                    <path
                      d="M169.6,152a48.1,48.1,0,0,1-83.2,0"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="16"
                    ></path>
                  </svg>
                  <p className="text-xl font-semibold font-primary text-center">
                    There are no assessment taken.
                  </p>
                  <NavLink to={`/student/assesment`}>
                    <button className="bg-brand-500 w-64 mx-auto flex justify-center font-primary text-white px-5 py-1 rounded-lg capitalize">
                      Take an assessment
                    </button>
                  </NavLink>
                </div>
              )}
            </div>
            {paginatedData && getSearch && (
              <div
                className={`${
                  studentResult.length >= 8
                    ? paginatedData.length >= 5
                      ? "mb-0"
                      : "absolute left-0 right-0 bottom-20 "
                    : "mx-auto hidden"
                }`}
              >
                <PaginationData
                  data={studentResult}
                  paginatedData={setPaginatedData}
                  userId={email?.email}
                  getSearchActive={isSearch}
                  type="Studentresult"
                  domainId=" "
                  name={input}
                />
              </div>
            )}
          </section>
        </>
      )}
    </div>
  );
};
