import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Table,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { Link } from "react-router-dom";
import "./assessmentTable.css";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { InputField } from "../inputFields/InputField";
import type { RadioChangeEvent } from "antd";
import {
  assessmentDelete,
  invitStudentByGmail,
  sendReminderByGmail,
} from "../../serviceApi/adminApi/AssessmentApi";
import Swal from "sweetalert2";
import { QuestionCircleOutlined } from "@ant-design/icons";
import date from "date-and-time";
import {
  addAssessmentEvents,
  getEventRegisterDetail,
} from "../../serviceApi/adminApi/EventApi";
import { loginContext } from "../context/LoginContext";
import { useAuth0 } from "@auth0/auth0-react";
interface DataType {
  name: string | null;
  duration: number;
  id: number;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const AssesmentDataTable = (assessmentList) => {
  const { user } = useAuth0();
  const email: any = user?.email;
  const navigate = useNavigate();
  const { eventId, setEventId, isShare, setIsShare }: any =
    useContext(loginContext);
  const { domainId, categoryId, skillId } = useParams();
  const [userRegisterData, setRegisterUserData] = useState<any>([]);
  const [modal2Open, setModal2Open] = React.useState(false);
  const [emailId, setEmailId] = useState("");
  const [assessmentId, setAssessmentId] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [status, setStatus] = useState(true);
  const [page, setPage] = React.useState(1);
  const handleOpen = (uuid: string) => {
    setModal2Open(true);
    setAssessmentId(uuid);
  };

  const [addAssessmentId, setAddAssessmentId] = useState<any>([]);

  const onChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAddAssessmentId([value]);
    } else {
      setAddAssessmentId((prevValues) =>
        prevValues.filter((item) => item !== value)
      );
    }
  };

  const deleteAssessment = async (uuid: string) => {
    const response = await assessmentDelete(uuid);
    if (response) {
      assessmentList.deleteStatus(true);
    }
  };

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    setEventId("");
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      render: (id, record, index: number) => {
        return (
          <p className="text-center">
            {(page - 1) * Number(tableParams?.pagination?.pageSize) + index + 1}
          </p>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.name - b.name,
      render: (name) => {
        return <p className="text-center">{name}</p>;
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.duration - b.duration,
      render: (duration) => {
        return <p className="text-center">{duration}</p>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (type) => {
        return <p className="text-center capitalize">{type}</p>;
      },
    },

    {
      title: "Action",
      dataIndex: "uuid",
      render: (uuid, a: any) => {
        return (
          <div className="flex flex-row justify-center space-x-3">
            {isShare && eventId && a?.type == "private" ? (
              //for send invitation to student for private assessment on admin dashboard
              <button
                onClick={() => handleOpen(uuid)}
                className="px-2 py-2 bg-green-500 hover:bg-green-800 text-white text-base rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="text-white w-4 h-4"
                  viewBox="0 0 256 256"
                >
                  <path d="M237.66,106.35l-80-80A8,8,0,0,0,144,32V72.35c-25.94,2.22-54.59,14.92-78.16,34.91-28.38,24.08-46.05,55.11-49.76,87.37a12,12,0,0,0,20.68,9.58h0c11-11.71,50.14-48.74,107.24-52V192a8,8,0,0,0,13.66,5.65l80-80A8,8,0,0,0,237.66,106.35ZM160,172.69V144a8,8,0,0,0-8-8c-28.08,0-55.43,7.33-81.29,21.8a196.17,196.17,0,0,0-36.57,26.52c5.8-23.84,20.42-46.51,42.05-64.86C99.41,99.77,127.75,88,152,88a8,8,0,0,0,8-8V51.32L220.69,112Z"></path>
                </svg>
              </button>
            ) : eventId && a?.type == "private" ? (
              <button>
                {/* <Radio.Group onChange={onChange} value={uuid}> */}
                <Radio
                  key={uuid}
                  value={uuid}
                  className="answer-input-option"
                  onChange={onChange}
                  name={uuid}
                  id={uuid}
                  checked={addAssessmentId?.includes(uuid)}
                ></Radio>
                {/* </Radio.Group> */}
              </button>
            ) : (
              <>
                <Tooltip title="Create and see questions">
                  <Link
                    to={`/subadmin/domain/skill/${skillId}/assessment/${uuid}/questions/list`}
                  >
                    <button className="px-2 py-2 bg-blue-500 hover:bg-brand-700 text-white text-base rounded-full w-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </button>
                  </Link>
                </Tooltip>
                <Link
                  to={`/subadmin/domain/skills/${skillId}/assessment/${uuid}/edit`}
                  className="px-2 py-2 bg-black hover:bg-gray-600  hover:text-white text-white text-base rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </Link>
                <Popconfirm
                  title="Are you sure to delete this Assessment?"
                  onConfirm={() => deleteAssessment(uuid)}
                  okButtonProps={{ loading: false }}
                  icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                  <button
                    className="px-2 py-2 bg-red-600 hover:bg-red-800 text-white text-base rounded-full"
                    disabled={
                      assessmentList?.assessmentList?.assessments?.active ==
                      "true"
                        ? true
                        : false
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </Popconfirm>
                {a?.type == "private" && ( //for send invitation to student for private assessment on admin dashboard
                  <Tooltip title="share an invitation">
                    <button
                      onClick={() => handleOpen(uuid)}
                      className="px-2 py-2 bg-green-500 hover:bg-green-800 text-white text-base rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="text-white w-4 h-4"
                        viewBox="0 0 256 256"
                      >
                        <path d="M237.66,106.35l-80-80A8,8,0,0,0,144,32V72.35c-25.94,2.22-54.59,14.92-78.16,34.91-28.38,24.08-46.05,55.11-49.76,87.37a12,12,0,0,0,20.68,9.58h0c11-11.71,50.14-48.74,107.24-52V192a8,8,0,0,0,13.66,5.65l80-80A8,8,0,0,0,237.66,106.35ZM160,172.69V144a8,8,0,0,0-8-8c-28.08,0-55.43,7.33-81.29,21.8a196.17,196.17,0,0,0-36.57,26.52c5.8-23.84,20.42-46.51,42.05-64.86C99.41,99.77,127.75,88,152,88a8,8,0,0,0,8-8V51.32L220.69,112Z"></path>
                      </svg>
                    </button>
                  </Tooltip>
                )}

                {a?.type == "private" && ( //for student reports data for private assessment on admin dashboard
                  <Tooltip title="see report details">
                    <Link
                      to={`/subadmin/domain/skill/${skillId}/assessment/${uuid}/students/reports`}
                    >
                      <button className="px-2 py-2 bg-green-500 hover:bg-green-800 text-white text-base rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="text-white w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                          />
                        </svg>
                      </button>
                    </Link>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: page,
      pageSize: 5,
    },
  });
  const sendInvitation = async () => {
    const currentDate = new Date();
    const expiryDate: any = date.format(currentDate, "YYYY-MM-DD");
    try {
      const result = await invitStudentByGmail({
        emailId,
        domainId,
        categoryId,
        skillId,
        assessmentId,
        expiryDate,
      });
      if (result?.data?.createInvitation) {
        setModal2Open(false);
        Swal.fire({
          icon: "success",
          title: "Invitation request has been sent",
          showConfirmButton: false,
          timer: 4000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to sent Invitation Email Something went wrong !",
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong !",
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };

  // const sendReminder = async () => {
  //   const currentDate = new Date();
  //   const expiryDate: any = date.format(currentDate, "YYYY-MM-DD");
  //   // const expiryDate = "2023-05-04";
  //   try {
  //     const result = await sendReminderByGmail({
  //       // eventId
  //     });
  //     if (result?.data?.createInvitation) {
  //       setModal2Open(false);
  //       Swal.fire({
  //         icon: "success",
  //         title: "Invitation request has been sent",
  //         showConfirmButton: false,
  //         timer: 4000,
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Failed to sent Invitation Email Something went wrong !",
  //         showConfirmButton: false,
  //         timer: 4000,
  //       });
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Something went wrong !",
  //       showConfirmButton: false,
  //       timer: 4000,
  //     });
  //   }
  // };

  useEffect(() => {
    // fetchData();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    if (eventId) {
      if (emailId?.length > 0) {
        setStatus(false);
      } else {
        setStatus(true);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (mailformat.test(emailId)) {
        setStatus(false);
      } else {
        setStatus(true);
      }
    }
  }, [emailId]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };
  const getEventsRegisterUser = async () => {
    const res = await getEventRegisterDetail(email, assessmentId, eventId);
    const userData = res?.data?.eventRegisterUser;
    const newArray = userData?.map((m) => [m.userId, m]);
    const newMap = new Map(newArray);
    const iterator: any = newMap.values();
    const uniqueResult: any = [...iterator];
    setRegisterUserData(uniqueResult);
  };

  useEffect(() => {
    if (isShare) {
      getEventsRegisterUser();
    }
  }, [assessmentId]);
  const emailData: any = userRegisterData?.map((data, index) => {
    return {
      value: data?.userId,
      label: data?.userId,
    };
  });
  const handleAdd = async () => {
    const eventInput = { eventId: eventId, assessmentId: addAssessmentId[0] };
    const res = await addAssessmentEvents(eventInput);
    if (res?.data?.addAssessmentevent?.message == 0) {
      Swal.fire({
        icon: "success",
        title: "Assessment Already Added",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Assessment Added",
        showConfirmButton: false,
        timer: 3000,
      });
      navigate("/subadmin/admin/create-event");
    }
  };
  return (
    <>
      {eventId && (
        <button
          disabled={addAssessmentId?.length > 0 ? false : true}
          className={`m-5  rounded p-2  text-white text-sm ${
            addAssessmentId?.length > 0
              ? "bg-brand cursor-pointer hover:bg-blue-500"
              : `bg-gray-300 cursor-not-allowed`
          }`}
          onClick={(e) => handleAdd()}
        >
          Add
        </button>
      )}
      <Table
        columns={columns}
        className="domain-request-table"
        rowKey={(record) => record.id}
        dataSource={assessmentList.assessmentList}
        pagination={{
          onChange(current) {
            setPage(current);
          },
          defaultPageSize: 5,
          hideOnSinglePage: false,
          showSizeChanger: true,
        }}
        loading={false}
        onChange={handleTableChange}
      />
      <Modal
        title="Send Invitation"
        centered
        open={modal2Open}
        className="cat-modal"
        onCancel={() => setModal2Open(false)}
      >
        <div>
          {eventId ? (
            <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black">
              Select Email Id <span className="text-red-600">*</span>
            </p>
          ) : (
            ""
          )}
          {eventId ? (
            <Select
              onChange={setEmailId}
              showSearch
              mode="multiple"
              className="md:w-full w-full assessment-validity rounded-md"
              placeholder="Search / Select email"
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={emailData}
            />
          ) : (
            <InputField
              type="email"
              onChange={setEmailId}
              placeholder="Name"
              id="Email Id"
              emailValidCheck={setEmailErr}
            />
          )}
        </div>
        <span className="text-red-500 mt-2">{emailErr}</span>
        <div className="mt-8">
          <PrimaryButton
            onclick={sendInvitation}
            disabledStatus={status}
            text="Send"
            className={`${
              status
                ? "bg-gray-300 cursor-not-allowed border-gray-300"
                : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
            }`}
          />
        </div>
      </Modal>
    </>
  );
};
